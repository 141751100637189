<template>
  <!-- <div class="alert alert-warning" role="alert">
      teste
    </div> -->

  <div class="card shadow mb-4">
    <div class="card-body card-modal-default">
      <!-- principal de iten do contrato -->

      <div class="card-content" :data-toggle="!contrato.exibeSecuritizado ? false : 'modal'"
        :data-target="`#details-item-${contrato.numeroContrato}`">
        <div class="col1">
          <div class="title">
            <small>{{ contrato.direcionamento }}</small>
            <h2 class>{{ contrato.descricao }}</h2>
          </div>
          <div class="number">
            <small>Número do Contrato</small>
            <p>{{ contrato.numeroContrato }}</p>
          </div>
        </div>
        <template v-if="contrato.exibeSecuritizado">
          <div class="col2 grafico">
            <div class="chart-default" style="width: 140px;height: 140px;">
              <canvas :id="`grafico-financiamento-${contrato.numeroContrato}`"></canvas>
            </div>
            <div class="text-center lh-1">
              <small>
                <strong>{{ ((100 * contrato.qtdParcelasPagas) / contrato.qtdParcelas).toFixed(0) }}% <br></strong>
                <span>Parcelas pagas: <br></span>
                <strong>{{ contrato.qtdParcelasPagas }}/{{ contrato.qtdParcelas }}</strong>
              </small>
            </div>
          </div>
        </template>
      </div>

      <div class="card-bottom-button">
        <div class="text-center" :id="`mensagemDetalhes-${contrato.numeroContrato}`">
          <template v-if="contrato.exibeLiquidadoCessao">
            <span :id="`mensagem-${contrato.numeroContrato}`">{{contrato.liquidadoCessao}}</span>
          </template>
          <template v-else>
            <template v-if="contrato.exibeSecuritizado">
              <button :id="`btnDetalhes-${contrato.numeroContrato}`" data-toggle="modal"
                :data-target="`#details-item-${contrato.numeroContrato}`" type="button"
                class="btn btn-link btn-link-gray">Ver Detalhes</button>
            </template>
            <template v-else>
              <span :id="`mensagem-${contrato.numeroContrato}`">Prezado(a), para negociar o seu financiamento, por
                gentileza entrar em contato no telefone (11) 5555-5380 Ramais 3371 ou 3352.</span>
            </template>
          </template>
        </div>
      </div>

      <template v-if="contrato.exibeSecuritizado">
        <div :id="`details-item-${contrato.numeroContrato}`" v-show="contrato.exibeSecuritizado" class="modal modal-forms"
          tabindex="-1" role="dialog">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="title">
                  <small>{{ contrato.direcionamento }}</small>
                  <h2 class>{{ contrato.descricao }}</h2>
                </div>
                <div class="number">
                  <small>Número do Contrato</small>
                  <p>{{ contrato.numeroContrato }}</p>
                </div>
                <!-- <h4 class="modal-title">Dados do contrato</h4> -->
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">

                <!-- detalhes de iten do contrato -->
                <div class="col-12 mb-4">
                  <table class="table table-hover table-left-right dados-contrato">
                    <tbody>
                      <tr>
                        <td>Valor financiado</td>
                        <td>{{ contrato.valorFinanciado | formatCurrency }}</td>
                      </tr>
                      <tr>
                        <td>Início do contrato</td>
                        <td> {{ contrato.emissao | formatDate }} </td>
                      </tr>
                      <tr>
                        <td>Situação</td>
                        <td>{{ contrato.situacao | formatParcelaStatus }}</td>
                      </tr>
                      <tr>
                        <td>Parcelas pagas</td>
                        <td>{{ contrato.qtdParcelasPagas }}</td>
                      </tr>
                      <tr>
                        <td>Total de parcelas</td>
                        <td>{{ contrato.qtdParcelas }}</td>
                      </tr>
                      <tr>
                        <td>Primeiro vencimento</td>
                        <td>{{ contrato.primeiroVencimento | formatDate }}</td>
                      </tr>
                      <tr>
                        <td>Último vencimento</td>
                        <td>{{ contrato.ultimoVencimento | formatDate }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center">
                    <p class="alert alert-danger p-2" v-if="exibeErroDownload">
                      Caso não consiga visualizar seu contrato, solicitar o documento em nosso e-mail "backoffice_produtosCDC@bancodigimais.com.br".<br> 
                    </p>
                    <p class="alert alert-danger p-2" v-if="exibeErroArquivoNaoLocalizado">
                      Caso não consiga visualizar seu contrato, solicitar o documento em nosso e-mail
                      “backoffice_produtosCDC@bancodigimais.com.br”.<br>
                      Arquivo não localizado.
                    </p>
                    <p class="alert alert-danger p-2" v-if="exibeErroDownloadNotCCB">
                      Caso não consiga visualizar seu documento, solicitar o mesmo em nossos canais de atendimento:<br>
                        Capitais e regiões metropolitanas 4020-3300<br>
                        Outras localidades 0800-646-7676
                    </p>

                    <button type="button"
                      class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2"  v-on:click="downloadPDFContrato">
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoCCB">
                        <span class="sr-only">Loading...</span>
                      </div>
                      CCB
                    </button>
                    <button type="button"
                      class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2"
                      v-on:click="downloadPDFCertificadoSeguro" v-show="exibeBotaoSeguro">
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status"
                        v-if="carregandoCertificadoSeguro">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Seguro
                    </button>
                    <button type="button"
                      class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2"
                      v-on:click="downloadPDFExtrato" :disabled="!exibeBotaoExtrato">
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status"
                        v-if="carregandoExtrato">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Extrato
                    </button>
                    <button type="button"
                      class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2"
                      v-on:click="downloadPDFDeclaracao" :disabled="!exibeBotaoDeclaracao">
                      <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status"
                        v-if="carregandoDeclaracao">
                        <span class="sr-only">Loading...</span>
                      </div>
                      Declaração de Quitação de Débito Anual
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- fim principal de iten do contrato -->
      <div class="row collapse hide" :id="`details-item-${contrato.numeroContrato}`">
        <!-- detalhes de iten do contrato -->
        <div class="col-12 mb-4">
          <hr />
          <h3 class="text-center my-4">Dados do contrato</h3>
          <table class="table table-hover table-left-right dados-contrato">
            <tbody>
              <tr>
                <td>Valor financiado</td>
                <td>{{ contrato.valorFinanciado | formatCurrency }}</td>
              </tr>
              <tr>
                <td>Início do contrato</td>
                <td> {{ contrato.emissao | formatDate }} </td>
              </tr>
              <tr>
                <td>Situação</td>
                <td>{{ contrato.situacao | formatParcelaStatus }}</td>
              </tr>
              <tr>
                <td>Parcelas pagas</td>
                <td>{{ contrato.qtdParcelasPagas }}</td>
              </tr>
              <tr>
                <td>Total de parcelas</td>
                <td>{{ contrato.qtdParcelas }}</td>
              </tr>
              <tr>
                <td>Primeiro vencimento</td>
                <td>{{ contrato.primeiroVencimento | formatDate }}</td>
              </tr>
              <tr>
                <td>Último vencimento</td>
                <td>{{ contrato.ultimoVencimento | formatDate }}</td>
              </tr>
            </tbody>
          </table>
          <div class="text-center">
             <p class="alert alert-danger p-2" v-if="exibeErroArquivoNaoLocalizado">
                Arquivo não localizado.
              </p>
              <p class="alert alert-danger p-2" v-if="exibeErroContratoLiquidado">
                Seu contrato está quitado, favor solicitar segunda via de contrato através da central de atendimento do banco.
              </p>
              <button type="button" class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2" v-on:click="downloadPDFContrato">
                <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoCCB">
                  <span class="sr-only">Loading...</span>
                </div>
                CCB
              </button>
              <button type="button" class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2" v-on:click="downloadPDFCertificadoSeguro">
                <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoCertificadoSeguro">
                  <span class="sr-only">Loading...</span>
                </div>
                Seguro
              </button>
              <button 
                type="button" 
                class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2" 
                v-on:click="downloadPDFExtrato"
                :disabled="!exibeBotaoExtrato"
              >
                <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoExtrato">
                  <span class="sr-only">Loading...</span>
                </div>
                Extrato
              </button>
              <button 
                type="button" 
                class="btn btn-primary d-sm-down-block mx-sm-down-auto mb-1 mx-auto mr-sm-3 mt-2" 
                v-on:click="downloadPDFDeclaracao"
                :disabled="!exibeBotaoDeclaracao"
              >
                <div class="spinner-border spinner-border-sm" aria-hidden="true" role="status" v-if="carregandoDeclaracao">
                  <span class="sr-only">Loading...</span>
                </div>
                Declaração de Quitação de Débito Anual
              </button>
            </div>
          </div>
        </div>

    </div>
  </div>
  <!-- </div> -->
</template>
<script>
// import Contratos from "../api_portal_auto_atendimento/src/model/Contratos";
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import Chart from 'chart.js';

export default {
  name: 'ContratoItem',
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      processing: false,
      buttonPressed: 0,
      downloadError: false,
      arquivoNaoLocalizadoError: false,
      //downloadErrorExtrato: false,
      downloadErrorNotCCB:false,
      contratoNaoLocalizadoError: false
    }
  },
  computed: {
    exibeErroDownload: function () {
      return this.downloadError;
    },
    exibeErroContratoLiquidado: function () {
      return this.contratoNaoLocalizadoError;
    },
    //exibeErroDownloadExtrato: function () {
    //  return this.downloadErrorExtrato;
    //},
    exibeErroArquivoNaoLocalizado:function () {
      return this.arquivoNaoLocalizadoError;
    },
    exibeErroDownloadNotCCB:function(){
      return this.downloadErrorNotCCB;
    },
    carregando: function() {
      return this.processing;
    },
    carregandoCCB: function () {
      return this.processing && this.buttonPressed == 1;
    },
    carregandoCertificadoSeguro: function () {
      return this.processing && this.buttonPressed == 2;
    },
    carregandoExtrato: function () {
      return this.processing && this.buttonPressed == 3;
    },
    carregandoDeclaracao: function () {
      return this.processing && this.buttonPressed == 4;
    },
    exibeBotaoExtrato: function () {
      return this.contrato.exibeExtrato;
    },
    exibeBotaoDeclaracao: function () {
      return this.contrato.exibeDeclaracao;
    },
    exibeBotaoSeguro: function () {
      return this.contrato.exibeSeguro;
    }
  },
  props: {
    contrato: Object
  },
  methods: {
    download(base64String, filename, type) {

      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      var blob = new Blob([byteArray], { type: type });
      if (window.navigator.msSaveBlob) { // // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
        window.navigator.msSaveOrOpenBlob(blob, filename);
      }
      else {
        var a = window.document.createElement("a");
        window.URL = window.webkitURL || window.URL;
        a.href = window.URL.createObjectURL(blob, { type: type, oneTimeOnly: true });
        a.target = '_blank';
        a.download = filename;
        document.body.appendChild(a);
        a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
        document.body.removeChild(a);
      }

      // var a = document.createElement("a");
      // a.href = dataurl;
      // a.setAttribute("download", filename);
      // a.click();
    },
    downloadPDFContrato: function (event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.buttonPressed = 1;
      this.downloadError = false;
      //this.downloadErrorExtrato = false;
      this.arquivoNaoLocalizadoError = false;
      this.downloadErrorNotCCB= false;
      let config = this.authService.config();
      let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
      let userData = this.authService.userData();

      if (this.contrato.reneg == "S") {

        contratoApi.consultaAditivoReneg(userData.Empresa, this.contrato.agencia, this.contrato.numeroContrato, (error, data, response) => {
          this.processing = false;
          this.buttonPressed = 0;
          if (error) {
            this.downloadError = true;
          } else {
            if (data.base64) {
              this.download(data.base64, `CCB-${this.contrato.numeroContrato}.pdf`, "application/pdf");
            } else {
              this.arquivoNaoLocalizadoError = true;
            }
          }
        });
      }
      else {
        contratoApi.consultaContratoCCB(userData.Empresa, this.contrato.agencia, this.contrato.numeroContrato, (error, data, response) => {
          this.processing = false;
          this.buttonPressed = 0;
          if (error) {
            // console.error(error);
            // alert('Erro ao realizar download da CCB.')
            this.downloadError = true;
          } else {
            // let filename = this.contrato.numeroContrato + 'pdf';
            // let mimeType = 'application/pdf';
            // download(data, filename, mimeType)
            if (data.base64) {
              if (data.mimeType == "application/zip") {
                this.download(data.base64, `CCB-${this.contrato.numeroContrato}.zip`, data.mimeType);
              }
              if (data.mimeType == "application/pdf") {
                this.download(data.base64, `CCB-${this.contrato.numeroContrato}.pdf`, data.mimeType);
              }
            } else {
              this.arquivoNaoLocalizadoError = true;
            }
          }
        });
      }
    },
    downloadPDFCertificadoSeguro: function (event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.buttonPressed = 2;
      this.downloadError = false;
      //this.downloadErrorExtrato = false;
      this.arquivoNaoLocalizadoError = false;
      this.downloadErrorNotCCB= false;
      let config = this.authService.config();
      let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken();
      let userData = this.authService.userData();


      contratoApi.consultaCertificadoSeguro(userData.Empresa, this.contrato.agencia, this.contrato.numeroContrato, (error, data, response) => {
        this.processing = false;
        this.buttonPressed = 0;
        if (error) {
          // console.error(error);
          // alert('Erro ao realizar download da CCB.')
          this.downloadError = true;
        } else {
          // let filename = this.contrato.numeroContrato + 'pdf';
          // let mimeType = 'application/pdf';
          // download(data, filename, mimeType)
          if (data.base64) {
            this.download(data.base64, `CCB-${this.contrato.numeroContrato}.pdf`, data.mimeType);
          } else {
            this.arquivoNaoLocalizadoError = true;
          }
        }
      });
    },
    downloadPDFExtrato: function (event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;
      this.buttonPressed = 3;
      this.downloadError = false;
      //this.downloadErrorExtrato = false;
      this.arquivoNaoLocalizadoError = false;
      this.downloadErrorNotCCB= false;
      let config = this.authService.config();
      let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let userData = this.authService.userData();


      contratoApi.consultaExtratoContrato(userData.Empresa, this.contrato.agencia, this.contrato.numeroContrato, (error, data, response) => {
        this.processing = false;
        this.buttonPressed = 0;
        if (error) {
          this.downloadErrorNotCCB = true;
        } else {
          if (data.base64) {
            this.download(data.base64, `Extrato-${this.contrato.numeroContrato}.pdf`, 'application/pdf');
          }
        }
      });
    },
    downloadPDFDeclaracao: function (event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }

      this.processing = true;
      this.buttonPressed = 4;
      this.downloadError = false;
      //this.downloadErrorExtrato = false;
      this.arquivoNaoLocalizadoError = false;
      this.downloadErrorNotCCB= false;
      let config = this.authService.config();
      let contratoApi = new ApiPortalAutoAtendimento.ContratoApi();
      let defaultClient = ApiPortalAutoAtendimento.ApiClient.instance;
      defaultClient.basePath = config.endpoints.autoatendimento;
      defaultClient.authentications.oauth2.accessToken = this.authService.accessToken()
      let userData = this.authService.userData();

      contratoApi.consultaDeclaracaoContrato(userData.Empresa, this.contrato.agencia, this.contrato.numeroContrato, (error, data, response) => {
        this.processing = false;
        this.buttonPressed = 0;
        if (error) {
          this.downloadErrorNotCCB = true;
        } else {
          if (data.base64) {
            this.download(data.base64, `Declaracao-${this.contrato.numeroContrato}.pdf`, 'application/pdf');
          }
        }
      });
    }
  },
  mounted() {

    var modal_needfix = true;
    $(".modal").on('shown.bs.modal', function () {
      var modalscope = $(this);
      modalscope.css({ paddingRight: "" });
      if (modal_needfix) {
        window.addEventListener("resize", function () {
          requestAnimationFrame(function () {// be sure to act after the pluggin script
            modalscope.css({ paddingRight: "" });
          });
        });
        modal_needfix = false;
      }
    });

    this.$nextTick(() => {

      (Chart.defaults.global.defaultFontFamily = "Roboto"),
        //'-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif';
        Chart.defaults.global.defaultFontColor = "#333333";

      // Grafico Financiamento 01
      let ctx = document.getElementById("grafico-financiamento-" + this.contrato.numeroContrato);
      new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Pago", "Em aberto"],
          datasets: [{
            data: [this.contrato.qtdParcelasPagas, this.contrato.qtdParcelas - this.contrato.qtdParcelasPagas],
            backgroundColor: ['#00102F', '#FF3B47'],
            hoverBackgroundColor: ['#00102F', '#FF3B47'],
            hoverBorderColor: "rgba(234, 236, 244, 1)",
            borderWidth: 0,
          }]
        },
        options: {
          maintainAspectRatio: false,
          tooltips: {
            backgroundColor: "rgb(255,255,255)",
            bodyFontColor: "#333333",
            borderColor: "#dddfeb",
            borderWidth: 1,
            xPadding: 10,
            yPadding: 10,
            displayColors: false,
            caretPadding: 10,
          },
          legend: {
            display: false
          },
          cutoutPercentage: 80
        }
      });
    });
  }
}
</script>

<style scoped>
.dados-contrato tbody tr td:first-child {
  text-align: left;
}
</style>